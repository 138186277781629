import ProductComponent from "../Components/ProductsComponent";
import ProductsLotti from "../Components/ProductsLotti";

export default function Products() {
  return (
    <div className="overflow-hidden  mt-10 mb-20">
      <div class="-mx-4 flex flex-wrap">
        <ProductsLotti />
        <div class="w-full px-4">
          <div class="mx-auto  max-w-[510px] text-center lg:mb-20">
            <h2 class="mb-5 text-3xl font-semibold text-black md:text-center md:text-5xl">
              Recommended Products
            </h2>
          </div>
        </div>
      </div>
      <ProductComponent />
    </div>
  );
}
