import LactationLottie from "../Components/LactationLottie";
import TLN from "../Components/TLN";
import Prenatal from "../assets/lotti/prenatal-appointments1.png";
import Laction from "../assets/lotti/boobs1.png";
import Pump from "../assets/lotti/breast-pump1.png";
import LearnMoreComponent from "../Components/LearnMoreContact";

export default function LactationConsultation() {
  return (
    <section class="mt-10 mb-20 padding-10 overflow-hidden ">
      <div class="container mx-auto text-center ">
        <div class="-mx-4 flex flex-wrap">
          <LactationLottie />
          <div class="w-full px-4">
            <div class="mx-auto  max-w-[510px] text-center lg:mb-20">
              <h2 class="mb-10 text-3xl font-semibold text-black md:text-center md:text-5xl">
                Lactation Consultation
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div class="container mx-auto text-center">
        <div class="sm:w-3/4 px-4 md:w-1/1 lg:w-4/4 mx-auto ">
          <div class="">
            <div class="relative group mb-8  ">
              <div
                class="absolute transition
                 rounded-lg opacity-25 -inset-1 bg-gradient-to-r from-purple-500 to-pink-500 blur duration-400 group-hover:opacity-100 group-hover:duration-200"
              ></div>
              <div class="relative p-10 bg-white ring-1 ring-gray-900/5 rounded-[20px]   ">
                <p class="text-body-color">
                  We provide emergency and after hours visits to problem-solve
                  breastfeeding challenges. Sore nipples, low milk production,
                  infant weight loss, jaundice, engorgement, growth spurts are
                  common issues that mothers need assistance with. Our
                  philosophy is to prevent these issues by seeing you early and
                  often at 3 days, 7-10 days, and 3 weeks. By meeting regularly,
                  we prevent breastfeeding challenges and create an environment
                  for breastfeeding success and maternal confidence. Mothers
                  worry they won’t be able to make enough milk or successfully
                  breastfeed. With our assistance, we provide the tools to be
                  successful!
                </p>
                <div class="container mx-auto text-center mt-10 cursor-pointer">
                  <a
                    href="https://lactationnetwork.com/request-a-consultant/?group=0018V00002YjW90QAF&group_name=Breastfeeding%20Telehealth%20of%20Oklahoma,%20LLC&disable_visit_type"
                    class="block md:w-1/4 sm:3/4 text-center mx-auto rounded-md bg-purple-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-black-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    target="_blank"
                  >
                    Get started
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section class="mt-20 mb-20 padding-10">
        <div class="container mx-auto text-center ">
          <div class="-mx-4 flex flex-wrap">
            <div class="w-full px-4">
              <div class="mx-auto  text-center lg:mb-20">
                <h2 class="mb-5 text-3xl font-semibold text-black md:text-center md:text-3xl">
                  Other Services
                </h2>
              </div>
            </div>
          </div>
          <div class="-mx-0 flex flex-wrap  p-5">
            <div class="w-full px-4 md:w-1/2 lg:w-1/2 ">
              <a href="/prenatal" class="cursor-pointer">
                <div class="relative group mb-8  ">
                  <div
                    class="absolute transition
                 rounded-lg opacity-25 -inset-1 bg-gradient-to-r from-purple-500 to-pink-500 blur duration-400 group-hover:opacity-100 group-hover:duration-200"
                  ></div>
                  <div class="relative p-10 bg-white ring-1 ring-gray-900/5 rounded-[20px]   ">
                    <div class=" mb-8 flex h-[100px] w-[100px]flex items-center justify-center  mx-auto rounded-2xl">
                      <img
                        src={Prenatal}
                        className="h-[100px] w-[100px]"
                        alt=""
                      />
                    </div>
                    <h4 class="text-dark mb-3 text-xl font-semibold">
                      Prenatal Visits
                    </h4>
                    <p class="text-body-color">
                      Comprehensive Prenatal Care: Your Partner in a Healthy
                      Pregnancy Journey. Our expert team is here to support you
                      every step of the way, ensuring a safe and joyful
                      pregnancy experience.
                    </p>
                  </div>
                </div>
              </a>
            </div>

            <div class="w-full px-4 md:w-1/2 lg:w-1/2 ">
              <a href="/breastpump" class="cursor-pointer">
                <div class="relative group mb-8  ">
                  <div
                    class="absolute transition
                 rounded-lg opacity-25 -inset-1 bg-gradient-to-r from-purple-500 to-pink-500 blur duration-400 group-hover:opacity-100 group-hover:duration-200"
                  ></div>
                  <div class="relative p-10 bg-white ring-1 ring-gray-900/5 rounded-[20px]   ">
                    <div class=" mb-8 flex h-[100px] w-[100px]flex items-center justify-center  mx-auto rounded-2xl">
                      <img src={Pump} className="h-[100px] w-[100px]" alt="" />
                    </div>
                    <h4 class="text-dark mb-3 text-xl font-semibold">
                      Breastpump Consults
                    </h4>
                    <p class="text-body-color">
                      Expert Breast Pump Consultations: Get the Perfect Pumping
                      Solution for You and Your Baby's Needs. Start Your Journey
                      to Hassle-Free Pumping Today!
                    </p>
                    <br />
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>
      <LearnMoreComponent />
    </section>
  );
}
