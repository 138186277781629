import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Header from "./Components/Header.jsx";
import Home from "./Pages/Home.jsx";
import Services from "./Pages/Services";
import Footer from "./Components/Footer";
import Resources from "./Pages/Resources.jsx";
import About from "./Pages/About";
import PrenatalVisits from "./Pages/PrenatalVisits";
import LactationConsultation from "./Pages/LactationConsultation";
import BreastpumpConsults from "./Pages/BreastpumpConsults";
import Products from "./Pages/Products";

function App() {
  return (
    <div className="bg-gradient-to-r from-pink-50/100 via-purple-60/100 to-indigo-50/100 font-merriweather ">
      <BrowserRouter>
        <div>
          <Header />
        </div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route path="/resources" element={<Resources />} />
          <Route path="/about" element={<About />} />
          <Route path="/prenatal" element={<PrenatalVisits />} />
          <Route path="/lactation" element={<LactationConsultation />} />
          <Route path="/breastpump" element={<BreastpumpConsults />} />
          <Route path="/products" element={<Products />} />
        </Routes>
        <div>
          <Footer />
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
