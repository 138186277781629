import Prenatal from "../assets/lotti/prenatal-appointments1.png";
import Laction from "../assets/lotti/boobs1.png";
import Pump from "../assets/lotti/breast-pump1.png";
export default function Services() {
  return (
    <section class="mt-10 mb-20 padding-10">
      <div class="container mx-auto text-center ">
        <div class="-mx-4 flex flex-wrap">
          <div class="w-full px-4">
            <div class="mx-auto  max-w-[510px] text-center lg:mb-20">
              <h2 class="mb-5 text-3xl font-semibold text-black md:text-center md:text-5xl">
                Our Services
              </h2>
              <p class="text-body-color text-base mb-5 p-5">
                Empowering Moms, Nurturing Babies: Your Breastfeeding Journey
                Starts Here!
              </p>
            </div>
          </div>
        </div>
        <div class="-mx-0 flex flex-wrap  p-5">
          <div class="w-full px-4 md:w-1/2 lg:w-1/3 ">
            <a href="/prenatal" class="cursor-pointer">
              <div class="relative group mb-8  ">
                <div
                  class="absolute transition
                 rounded-lg opacity-25 -inset-1 bg-gradient-to-r from-purple-500 to-pink-500 blur duration-400 group-hover:opacity-100 group-hover:duration-200"
                ></div>
                <div class="relative p-10 bg-white ring-1 ring-gray-900/5 rounded-[20px]   ">
                  <div class=" mb-8 flex h-[100px] w-[100px]flex items-center justify-center  mx-auto rounded-2xl">
                    <img
                      src={Prenatal}
                      className="h-[100px] w-[100px]"
                      alt=""
                    />
                  </div>
                  <h4 class="text-dark mb-3 text-xl font-semibold">
                    Prenatal Visits
                  </h4>
                  <p class="text-body-color">
                    Comprehensive Prenatal Care: Your Partner in a Healthy
                    Pregnancy Journey. Our expert team is here to support you
                    every step of the way, ensuring a safe and joyful pregnancy
                    experience.
                  </p>
                </div>
              </div>
            </a>
          </div>

          <div class="w-full px-4 md:w-1/2 lg:w-1/3 ">
            <a href="/lactation" class="cursor-pointer">
              <div class="relative group mb-8  ">
                <div
                  class="absolute transition
                 rounded-lg opacity-25 -inset-1 bg-gradient-to-r from-purple-500 to-pink-500 blur duration-400 group-hover:opacity-100 group-hover:duration-200"
                ></div>
                <div class="relative p-10 bg-white ring-1 ring-gray-900/5 rounded-[20px]   ">
                  <div class=" mb-8 flex h-[100px] w-[100px]flex items-center justify-center  mx-auto rounded-2xl">
                    <img src={Laction} className="h-[100px] w-[100px]" alt="" />
                  </div>
                  <h4 class="text-dark mb-3 text-xl font-semibold">
                    Lactation Consultation
                  </h4>
                  <p class="text-body-color">
                    Expert Guidance for a Successful Breastfeeding Journey. Our
                    Lactation Consultation Services provide personalized support
                    and solutions for breastfeeding mothers and their babies.
                  </p>
                </div>
              </div>
            </a>
          </div>
          <div class="w-full px-4 md:w-1/2 lg:w-1/3 ">
            <a href="/breastpump" class="cursor-pointer">
              <div class="relative group mb-8  ">
                <div
                  class="absolute transition
                 rounded-lg opacity-25 -inset-1 bg-gradient-to-r from-purple-500 to-pink-500 blur duration-400 group-hover:opacity-100 group-hover:duration-200"
                ></div>
                <div class="relative p-10 bg-white ring-1 ring-gray-900/5 rounded-[20px]   ">
                  <div class=" mb-8 flex h-[100px] w-[100px]flex items-center justify-center  mx-auto rounded-2xl">
                    <img src={Pump} className="h-[100px] w-[100px]" alt="" />
                  </div>
                  <h4 class="text-dark mb-3 text-xl font-semibold">
                    Breastpump Consults
                  </h4>
                  <p class="text-body-color">
                    Expert Breast Pump Consultations: Get the Perfect Pumping
                    Solution for You and Your Baby's Needs. Start Your Journey
                    to Hassle-Free Pumping Today!
                  </p>
                  <br />
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
