import React, { useRef } from "react";
import Empty from "../Components/Empty";
import Swal from "sweetalert2";
import emailjs from "@emailjs/browser";

export default function LearnMoreComponent() {
  const form = useRef();

  const handleSubmit = (event) => {
    document.getElementById("my_form").reset();
  };

  const sendEmail = (e) => {
    e.preventDefault();
    // handleSubmit();

    emailjs
      .sendForm(
        "service_rgglrsb",
        "template_fnyyo7f",
        form.current,
        "r8lMTnRjmwX0Tl0Qj"
      )
      .then(
        (result) => {
          Swal.fire({
            icon: "success",
            title: `<h5 style='color:green'>Message Sent Successfully</h5>`,
            confirmButtonColor: "green",
          });
        },
        (error) => {
          Swal.fire({
            icon: "error",
            title: `<h5 style='color:red'>Ooops, something went wrong</h5>`,
            confirmButtonColor: "red",
          });
        }
      );
    e.target.reset();
  };
  return (
    <section class="mx-auto text-center">
      <div class="py-8  px-4 mx-auto max-w-screen-md">
        <h2 class="mb-10  text-2xl font-semibold text-black md:text-center md:text-3xl">
          Still have questions? Contact Us!
        </h2>

        <form id="my_form" ref={form} onSubmit={sendEmail} class="space-y-8">
          <div>
            <label
              for="email"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-900"
            >
              Your email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              class="shadow-sm  border border-gray-300 text-black text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
              placeholder="Email"
              required
            />
          </div>
          <div>
            <label
              for="subject"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-900"
            >
              Subject
            </label>
            <input
              type="text"
              id="subject"
              name="subject"
              class="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500  dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
              placeholder="Let us know how we can help you"
              required
            />
          </div>
          <div class="sm:col-span-2 ">
            <label
              for="message"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-900"
            >
              Your message
            </label>
            <textarea
              id="message"
              name="message"
              rows="6"
              class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-black-300 dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-primary-500 dark:focus:border-primary-500"
              placeholder="Message..."
            ></textarea>
          </div>
          <div className="flex flex-col items-center justify-center">
            <button
              class="py-3    px-5 text-sm font-medium text-center text-white rounded-lg bg-purple-500 sm:w-fit hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
              type="submit"
              onChange={handleSubmit}
            >
              Send message
            </button>
          </div>
        </form>
        <div className="p-2 w-full pt-8 mt-8 border-t border-gray-200 text-center">
          <div>
            <div class="mb-8 flex w-full ">
              <div class="w-full">
                <h4 class="text-dark mb-1 text-xl font-bold">Phone Number</h4>
                <p class="text-body-color text-base">405-633-6027</p>
              </div>
            </div>
            <div class="mb-8 text-center flex w-full">
              <div class="w-full">
                <h4 class="text-dark mb-1 text-xl font-bold">Email</h4>
                <p class="text-body-color text-base">acwingf@gmail.com</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Empty />
      </div>
    </section>
  );
}
