import React from "react";
export default function Hero() {
  return (
    <div class="mt-20 mb-20">
      <div class="relative isolate px-6 ">
        <div class="mx-auto max-w-2xl overflow-hidden ">
          <div class="text-center">
            <h1 class="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
              Breastfeeding Telehealth of Oklahoma
            </h1>
            <p class="mt-6 text-lg leading-8 text-gray-600">
              Get the help you deserve
            </p>
            <div class="mt-10 flex items-center justify-center gap-x-6">
              <a
                href="https://lactationnetwork.com/request-a-consultant/?group=0018V00002YjW90QAF&group_name=Breastfeeding%20Telehealth%20of%20Oklahoma,%20LLC&disable_visit_type"
                class="rounded-md bg-purple-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-black-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                target="_blank"
              >
                Get started
              </a>
              <a
                href="/about"
                class="text-sm font-semibold leading-6 text-gray-900"
              >
                Learn more <span aria-hidden="true">→</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
