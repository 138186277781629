import React from "react";
import AboutPhoto from "../assets/lotti/me.jpg";
import Lottie from "lottie-react";

import headerLottie from "../assets/lotti/GirlSayingHello.json";
import LearnMoreComponent from "../Components/LearnMoreContact";

export default function About() {
  return (
    // <div class="mt-10 relative isolate overflow-hidden  px-6  lg:overflow-visible lg:px-0">
    //   <div class="mx-auto text-center">
    //     <div class="">
    //       <div class="w-full px-4">
    //         <div class="mx-auto  max-w-[510px] text-center lg:mb-10">
    //
    //         </div>
    //         <img
    //           className="mb-10 mx-auto rounded-lg w-96 h-106"
    //           alt=""
    //           src={AboutPhoto}
    //         />
    //       </div>
    //     </div>

    //     <div class="">
    //       <div class="lg:pr-4">
    //         <div class="mx-auto  max-w-[810px] text-center lg:mb-20">
    //           <h2 className="font-bold text-2xl mb-8 ">Who we are</h2>
    //           <p className="text-1xl  mb-8">

    //           </p>
    //
    //
    //           <br />
    //           <br />
    //           <br />
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    //   <div className="h-100"></div>
    // </div>

    <section class="overflow-hidden py-8 lg:py-16 px-4 overflow-hidden  ">
      <h2 class="mx-auto text-center text-dark mb-5 text-3xl font-bold sm:text-4xl md:text-[40px]">
        About Us
      </h2>

      <p class="text-body-color text-center text-base mb-10">
        Breastfeeding Telehealth of Oklahoma
      </p>
      <div class="container mx-auto max-w-[910px] mb-20">
        <div class="-mx-4 flex flex-wrap items-center  justify-between">
          <div class="w-full  px-4 lg:w-6/12">
            <div class="-mx-3 flex items-center sm:-mx-4">
              <div class="w-full px-3 sm:px-4 ">
                <div class=" my-4 rounded-lg">
                  <img
                    src={AboutPhoto}
                    alt=""
                    class="mb-10 mx-auto rounded-lg   "
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="w-full px-4 lg:w-1/2 xl:w-5/12">
            <div class="mt-10 lg:mt-0 mx-auto text-center">
              <h2 className="font-bold text-2xl mb-8 ">Who we are</h2>

              <p class="text-body-color mb-8 text-base">
                Breastfeeding Telehealth of Oklahoma was founded by Anita
                Wingfield, RN, IBCLC, MS with the vision to provide lactation
                assistance in a convenient way for new families. Anita has been
                an RN for 25+ years, received a Masters degree in Nursing
                Education, and is passionate about helping new mothers to
                succeed at breastfeeding. She has been an International Board
                Certified Lactation Consultant (IBCLC) for 15+ years. She is
                proud to be one of the highest accredited lactation experts in
                the world. Anita offers compassionate, non-judgmental,
                personalized care to help families like yours thrive.
              </p>
              <h2 className="font-bold text-2xl mb-8">Our Vision</h2>
              <p class="mt-8 mb-8 ">
                Our vision is to create a community where every breastfeeding
                mother feels supported, confident, and equipped with the
                knowledge and resources needed to nourish their child. We
                believe that by offering telehealth services, we can bridge the
                gap between healthcare professionals and mothers in need,
                ensuring that quality breastfeeding support is readily available
                to all.
              </p>
            </div>
          </div>
        </div>
      </div>
      <LearnMoreComponent />
    </section>
  );
}
