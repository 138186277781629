import Lottie from "lottie-react";
import Hero from "./Hero";

import Pump from "../assets/lotti/breast-pump1.png";

export default function BreastPumpLotti() {
  return (
    <div className="flex items-center justify-center mx-auto mt-10 mb-10 h-10">
      <div className="flex flex-wrap justify-between items-center lg:mx-auto md:mx-auto md:m-0 max-w-screen-xl">
        <a href="/lactation" class="flex items-center flex-start">
          <img src={Pump} className=" w-[90px]  lg:w-[90px]" />
        </a>
      </div>
    </div>
  );
}
