import BreakResources from "../Components/BreakResources";
import ResourcesComponent from "../Components/ResourcesComponent";

export default function Resources() {
  return (
    <div className="overflow-hidden  ">
      <BreakResources />
      <ResourcesComponent />
    </div>
  );
}
