import Lottie from "lottie-react";
import Hero from "./Hero";

import Prenatal from "../assets/lotti/prenatal-appointments1.png";

export default function PrenatalLottie() {
  return (
    <div className="flex items-center justify-center mx-auto mt-10 mb-10 h-10">
      <div className="flex flex-wrap justify-between items-center lg:mx-auto md:mx-auto md:m-0 max-w-screen-xl">
        <a href="/prenatal" class="flex items-center flex-start">
          <img src={Prenatal} className=" w-[90px]  lg:w-[90px]" />
        </a>
      </div>
    </div>
  );
}
