import Lottie from "lottie-react";
import Hero from "./Hero";

import headerLottie from "../assets/resources2.png";

export default function BreakResources() {
  return (
    <div className="flex items-center justify-center mx-auto mt-20 mb-10 h-10">
      <div className="flex flex-wrap justify-between items-center lg:mx-auto md:mx-auto md:m-0 max-w-screen-xl">
        <a href="/" class="flex items-center flex-start">
          <img src={headerLottie} className=" w-[130px]  lg:w-[130px]" />
        </a>
      </div>
    </div>
  );
}
