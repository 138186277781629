export default function Testimonial() {
  return (
    <section id="testimonies" class="py-20 ">
      <div class="max-w-6xl mx-8 md:mx-10 lg:mx-20 xl:mx-auto   ">
        <div class="transition duration-500 ease-in-out transform scale-100 translate-x-0 translate-y-0 opacity-100">
          <div class=" mb-12 space-y-5 md:mb-16 mx-auto text-center">
            <div class="inline-block px-3 py-1 text-sm  font-semibold text-black rounded-lg md:text-center mx-auto text-center   bg-white ">
              Words from Others
            </div>
            <h1 class="mb-5 text-3xl font-semibold text-black md:text-center md:text-5xl mx-auto text-center">
              It's not just us.
            </h1>
            <p class="text-xl text-black md:text-center md:text-2xl">
              Here's what others have to say.
            </p>
          </div>
        </div>

        <div class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 lg:gap-8">
          <ul class="space-y-8">
            <li class="text-sm leading-6">
              <div class="relative group">
                <div
                  class="absolute transition
                 rounded-lg opacity-25 -inset-1 bg-gradient-to-r from-purple-500 to-pink-500 blur duration-400 group-hover:opacity-100 group-hover:duration-200"
                ></div>
                <a href="/" class="cursor-pointer">
                  <div class="relative p-6 space-y-6 leading-none rounded-lg bg-white ring-1 ring-gray-900/5">
                    <div class="flex items-center space-x-4">
                      <div>
                        <h3 class="text-lg font-semibold text-black">
                          Celebrating Success
                        </h3>
                        <p class="text-gray-500 text-md">
                          Milestone at the 2-Month Mark
                        </p>
                      </div>
                    </div>
                    <p class="leading-normal text-black-300 text-md">
                      I’m so grateful for all of your help and encouragement to
                      stick with it!! We truly cannot thank you enough. We had
                      her 2 month well check this week and she is gaining weight
                      and thriving. She’s getting exclusive breastmilk (90%
                      nursing; bottles when we’re away from home), and we were
                      able to taper off the triple feeding about a month ago.
                    </p>
                  </div>
                </a>
              </div>
            </li>
            <li class="text-sm leading-6">
              <div class="relative group">
                <div
                  class="absolute transition
                 rounded-lg opacity-25 -inset-1 bg-gradient-to-r from-purple-500 to-pink-500 blur duration-400 group-hover:opacity-100 group-hover:duration-200"
                ></div>
                <a href="/" class="cursor-pointer">
                  <div class="relative p-6 space-y-6 leading-none rounded-lg bg-white ring-1 ring-gray-900/5">
                    <div class="flex items-center space-x-4">
                      <div>
                        <h3 class="text-lg font-semibold text-black">
                          First Time Mother
                        </h3>
                        <p class="text-gray-500 text-md">
                          Embracing the Beautiful Journey
                        </p>
                      </div>
                    </div>
                    <p class="leading-normal text-black-300 text-md">
                      I had the most horrible engorgement. Her strategies helped
                      so much. I would have quit breastfeeding without Anita's
                      advice and knowledge.
                    </p>
                  </div>
                </a>
              </div>
            </li>
          </ul>

          <ul class="hidden space-y-8 sm:block">
            <li class="text-sm leading-6">
              <div class="relative group">
                <div
                  class="absolute transition
                 rounded-lg opacity-25 -inset-1 bg-gradient-to-r from-purple-500 to-pink-500 blur duration-400 group-hover:opacity-100 group-hover:duration-200"
                ></div>
                <a href="/" class="cursor-pointer">
                  <div class="relative p-6 space-y-6 leading-none rounded-lg bg-white ring-1 ring-gray-900/5">
                    <div class="flex items-center space-x-4">
                      <div>
                        <h3 class="text-lg font-semibold text-black">
                          Mother of a near term infant
                        </h3>
                        <p class="text-gray-500 text-md">36 weeks</p>
                      </div>
                    </div>
                    <p class="leading-normal text-black-300 text-md">
                      Your advice saved us. We are exclusively back on the
                      breast with no pumping. Baby gained 1 lb and 2 oz in 8
                      days. Thank you again!
                    </p>
                  </div>
                </a>
              </div>
            </li>
            <li class="text-sm leading-6">
              <div class="relative group">
                <div
                  class="absolute transition
                 rounded-lg opacity-25 -inset-1 bg-gradient-to-r from-purple-500 to-pink-500 blur duration-400 group-hover:opacity-100 group-hover:duration-200"
                ></div>
                <a href="/" class="cursor-pointer">
                  <div class="relative p-6 space-y-6 leading-none rounded-lg bg-white ring-1 ring-gray-900/5">
                    <div class="flex items-center space-x-4">
                      <div>
                        <h3 class="text-lg font-semibold text-black">
                          Gratitude for the Amazing Support
                        </h3>
                        <p class="text-gray-500 text-md">
                          Embracing the Beautiful Journey
                        </p>
                      </div>
                    </div>
                    <p class="leading-normal text-black-300 text-md">
                      Breastfeeding has been an incredible journey for us, and
                      we couldn't have done it without the amazing support we
                      received. We are so grateful for all the encouragement and
                      guidance that helped us stick with it.
                    </p>
                  </div>
                </a>
              </div>
            </li>
          </ul>

          <ul class="hidden space-y-8 lg:block">
            <li class="text-sm leading-6">
              <div class="relative group">
                <div
                  class="absolute transition
                 rounded-lg opacity-25 -inset-1 bg-gradient-to-r from-purple-500 to-pink-500 blur duration-400 group-hover:opacity-100 group-hover:duration-200"
                ></div>
                <a href="/" class="cursor-pointer">
                  <div class="relative p-6 space-y-6 leading-none rounded-lg bg-white ring-1 ring-gray-900/5">
                    <div class="flex items-center space-x-4">
                      <div>
                        <h3 class="text-lg font-semibold text-black">
                          Class Attendant
                        </h3>
                        <p class="text-gray-500 text-md">
                          Making Sense of the Breastfeeding Journey
                        </p>
                      </div>
                    </div>
                    <p class="leading-normal text-black-300 text-md">
                      Your breastfeeding class just put everything together.
                      There is so much information but you made it make sense.
                    </p>
                  </div>
                </a>
              </div>
            </li>
            <li class="text-sm leading-6">
              <div class="relative group">
                <div
                  class="absolute transition
                 rounded-lg opacity-25 -inset-1 bg-gradient-to-r from-purple-500 to-pink-500 blur duration-400 group-hover:opacity-100 group-hover:duration-200"
                ></div>
                <a href="/" class="cursor-pointer">
                  <div class="relative p-6 space-y-6 leading-none rounded-lg bg-white ring-1 ring-gray-900/5">
                    <div class="flex items-center space-x-4">
                      <div>
                        <h3 class="text-lg font-semibold text-black">
                          Support for New Moms
                        </h3>
                        <p class="text-gray-500 text-md">Breastfeeding Bliss</p>
                      </div>
                    </div>
                    <p class="leading-normal text-black-300 text-md">
                      As a new mom, I was initially overwhelmed by the idea of
                      breastfeeding. However, with the incredible support and
                      guidance I received. My breastfeeding journey became an
                      empowering and beautiful experience. Anita's knowledge,
                      compassion, and dedication made all the difference. I can
                      confidently say that breastfeeding has been a bonding
                      experience like no other, thanks to her assistance. I'm
                      forever grateful!
                    </p>
                  </div>
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}
