import Prenatal from "../assets/lotti/1-number.png";
// import Prenatal from "../assets/lotti/prenatal-appointments1.png";
import Laction from "../assets/lotti/2-number.png";
import Pump from "../assets/lotti/3-number.png";

export default function TLN() {
  return (
    <div class="container mx-auto h-auto text-center  ">
      <div class="mx-auto flex flex-wrap  p-5">
        <div class="w-full px-4 ">
          <div class="relative group mb-8  ">
            <div
              class="absolute transition
                 rounded-lg opacity-25 -inset-1 bg-gradient-to-r from-purple-500 to-pink-500 blur duration-400 group-hover:opacity-100 group-hover:duration-200"
            ></div>
            <div class="relative p-10 bg-white ring-1 ring-gray-900/5 rounded-[20px]   ">
              <h4 class="text-dark mb-3 text-xl font-semibold">
                HOW TO GET YOUR IBCLC CONSULTATION THROUGH TLN
              </h4>

              <div class="-mx-0 flex flex-wrap w-full p-2 ">
                <div class="w-full px-4 md:w-1/2 lg:w-1/3">
                  <div class="mb-8 rounded-[20px] bg-white  ">
                    <div class="bg-primary mb-8 flex h-[100px] w-[100px]flex items-center text-center justify-center mx-auto rounded-2xl">
                      <img
                        src={Prenatal}
                        className="h-[100px] w-[100px]"
                        alt=""
                      />
                    </div>
                    <h4 class="text-dark mb-3 text-xl font-semibold">Step 1</h4>
                    <p class="text-body-color  ">
                      Fill out a consultation request form{" "}
                      <a
                        href="https://lactationnetwork.com/request-a-consultant/?group=0018V00002YjW90QAF&group_name=Breastfeeding%20Telehealth%20of%20Oklahoma,%20LLC&disable_visit_type"
                        className="text-purple-500"
                        target="_blank"
                      >
                        <b>here.</b>
                      </a>
                    </p>
                  </div>
                </div>
                <div class="w-full px-4 md:w-1/2 lg:w-1/3 ">
                  <div class="mb-8 rounded-[20px] bg-white  ">
                    <div class="bg-primary mb-8 flex h-[100px] w-[100px]flex items-center justify-center mx-auto rounded-2xl">
                      <img
                        src={Laction}
                        className="h-[100px] w-[100px]"
                        alt=""
                      />
                    </div>
                    <h4 class="text-dark mb-3 text-xl font-semibold">Step 2</h4>
                    <p class="text-body-color">
                      TLN checks your insurance coverage. If you're covered,
                      they'll bill your insurance directly. That means{" "}
                      <b>no out-of-pocket costs or paperwork</b> for you.
                    </p>
                  </div>
                </div>
                <div class="w-full px-4 md:w-1/2 lg:w-1/3">
                  <div class="mb-8 rounded-[20px] bg-white ">
                    <div class="bg-primary mb-8 flex h-[100px] w-[100px]flex items-center justify-center mx-auto rounded-2xl">
                      <img src={Pump} className="h-[100px] w-[100px]" alt="" />
                    </div>
                    <h4 class="text-dark mb-3 text-xl font-semibold">Step 3</h4>
                    <p class="text-body-color">
                      While you're there, browse TLN's{" "}
                      <b>
                        wide selection of insurance-covered lataction products
                      </b>{" "}
                      to help you meet your feeding goals.
                    </p>
                  </div>
                  <p class="text-dark mb-3 font-semibold">
                    *Some exclusions may apply
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
