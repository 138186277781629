import BreastPumpLotti from "../Components/BreastPumpLotti";
import TLN from "../Components/TLN";
import Prenatal from "../assets/lotti/prenatal-appointments1.png";
import Laction from "../assets/lotti/boobs1.png";
import Pump from "../assets/lotti/breast-pump1.png";
import LearnMoreComponent from "../Components/LearnMoreContact";

export default function BreastpumpConsults() {
  return (
    <section class="mt-10 mb-20 padding-10 overflow-hidden ">
      <div class="container mx-auto text-center ">
        <div class="-mx-4 flex flex-wrap">
          <BreastPumpLotti />
          <div class="w-full px-4">
            <div class="mx-auto  max-w-[510px] text-center lg:mb-20">
              <h2 class="mb-10 text-3xl font-semibold text-black md:text-center md:text-5xl">
                Breastpump Consults
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div class="container mx-auto text-center">
        <div class="sm:w-3/4 px-4 md:w-1/1 lg:w-4/4 mx-auto ">
          <div class="">
            <div class="relative group mb-8  ">
              <div
                class="absolute transition
                 rounded-lg opacity-25 -inset-1 bg-gradient-to-r from-purple-500 to-pink-500 blur duration-400 group-hover:opacity-100 group-hover:duration-200"
              ></div>
              <div class="relative p-10 bg-white ring-1 ring-gray-900/5 rounded-[20px]   ">
                <p class="text-body-color">
                  Most women have ordered and received a pump through their
                  insurance. Depending on your future plans for working outside
                  the home or if you need a pump for a preterm infant, we can
                  discuss and help you choose the appropriate pump. If you do
                  not have a pump, click this link to see what TLN has
                  available. They will file insurance for you as well. We
                  provide services to demonstrate the use of your pump and how
                  to be successful in whatever pumping situation you may have.
                </p>
                <div class="container mx-auto text-center mt-10 cursor-pointer">
                  <a
                    href="https://lactationnetwork.com/shop/"
                    class="block md:w-1/4 sm:3/4 text-center mx-auto rounded-md bg-purple-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-black-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    target="_blank"
                  >
                    Get started
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section class="mt-20 mb-20 padding-10">
        <div class="container mx-auto text-center ">
          <div class="-mx-4 flex flex-wrap">
            <div class="w-full px-4">
              <div class="mx-auto  text-center lg:mb-20">
                <h2 class="mb-5 text-3xl font-semibold text-black md:text-center md:text-3xl">
                  Other Services
                </h2>
              </div>
            </div>
          </div>
          <div class="-mx-0 flex flex-wrap  p-5">
            <div class="w-full px-4 md:w-1/2 lg:w-1/2 ">
              <a href="/prenatal" class="cursor-pointer">
                <div class="relative group mb-8  ">
                  <div
                    class="absolute transition
                 rounded-lg opacity-25 -inset-1 bg-gradient-to-r from-purple-500 to-pink-500 blur duration-400 group-hover:opacity-100 group-hover:duration-200"
                  ></div>
                  <div class="relative p-10 bg-white ring-1 ring-gray-900/5 rounded-[20px]   ">
                    <div class=" mb-8 flex h-[100px] w-[100px]flex items-center justify-center  mx-auto rounded-2xl">
                      <img
                        src={Prenatal}
                        className="h-[100px] w-[100px]"
                        alt=""
                      />
                    </div>
                    <h4 class="text-dark mb-3 text-xl font-semibold">
                      Prenatal Visits
                    </h4>
                    <p class="text-body-color">
                      Comprehensive Prenatal Care: Your Partner in a Healthy
                      Pregnancy Journey. Our expert team is here to support you
                      every step of the way, ensuring a safe and joyful
                      pregnancy experience.
                    </p>
                  </div>
                </div>
              </a>
            </div>

            <div class="w-full px-4 md:w-1/2 lg:w-1/2 ">
              <a href="/lactation" class="cursor-pointer">
                <div class="relative group mb-8  ">
                  <div
                    class="absolute transition
                 rounded-lg opacity-25 -inset-1 bg-gradient-to-r from-purple-500 to-pink-500 blur duration-400 group-hover:opacity-100 group-hover:duration-200"
                  ></div>
                  <div class="relative p-10 bg-white ring-1 ring-gray-900/5 rounded-[20px]   ">
                    <div class=" mb-8 flex h-[100px] w-[100px]flex items-center justify-center  mx-auto rounded-2xl">
                      <img
                        src={Laction}
                        className="h-[100px] w-[100px]"
                        alt=""
                      />
                    </div>
                    <h4 class="text-dark mb-3 text-xl font-semibold">
                      Lactation Consultation
                    </h4>
                    <p class="text-body-color">
                      Expert Guidance for a Successful Breastfeeding Journey.
                      Our Lactation Consultation Services provide personalized
                      support and solutions for breastfeeding mothers and their
                      babies.
                    </p>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>
      <LearnMoreComponent />
    </section>
  );
}
