import React from "react";
import Hero from "../Components/Hero";
import Services from "../Components/Services";
import BreakLotti from "../Components/BreakLotti";
import Footer from "../Components/Footer";
import TLN from "../Components/TLN";
import Testimonial from "../Components/Testimonial";
export default function Home() {
  return (
    <div className="overflow-hidden  ">
      <Hero className="" />
      <BreakLotti />
      <Services />
      <TLN />
      <Testimonial />
    </div>
  );
}
