import Droplet from "../assets/droplet.png";
import ibc from "../assets/ibc.jpg";
export default function ResourcesComponent() {
  return (
    <section class="mt-10 mb-20 padding-10">
      <div class="container mx-auto text-center ">
        <div class="-mx-4 flex flex-wrap">
          <div class="w-full px-4">
            <div class="mx-auto  max-w-[510px] text-center lg:mb-10">
              <h2 class="mb-5 text-3xl font-semibold text-black md:text-center md:text-5xl">
                Resources
              </h2>
            </div>
          </div>
        </div>

        <div class="-mx-4 flex flex-wrap items-center  justify-between mb-3">
          <div class="w-full  px-4 ">
            <div class="-mx-3 flex items-center sm:-mx-4">
              <div class="w-full px-3 sm:px-4 space-x-4 ">
                <div class="inline   ">
                  <a
                    type="button"
                    href="https://www.youtube.com/@anitawingfield6605/videos"
                    target="_blank"
                    data-te-ripple-init
                    data-te-ripple-color="light"
                    class="mb-2 inline-block rounded px-6 py-2.5 text-xs font-medium uppercase leading-normal text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg bg-red-500"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-4 w-4"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z" />
                    </svg>
                  </a>
                </div>
                <div class="inline   ">
                  <a
                    type="button"
                    href="https://www.facebook.com/profile.php?id=100068583723320&mibextid=LQQJ4d"
                    target="_blank"
                    data-te-ripple-init
                    data-te-ripple-color="light"
                    class="mb-2 inline-block rounded px-6 py-2.5 text-xs font-medium uppercase leading-normal text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg bg-blue-500"
                    // style="background-color: #1877f2"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-4 w-4"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
                    </svg>
                  </a>
                </div>
                <div class="inline">
                  <a
                    type="button"
                    href="https://www.instagram.com/bfthok/"
                    target="_blank"
                    data-te-ripple-init
                    data-te-ripple-color="light"
                    class="mb-2 inline-block rounded px-6 py-2.5 text-xs font-medium uppercase leading-normal text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg bg-purple-500"
                    // style="background-color: #c13584"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-4 w-4"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="-mx-4 flex flex-wrap ">
        <div class="w-full px-4">
          <div class="mx-auto  max-w-[510px] text-center lg:mb-10 mt-10">
            <h2 class="mb-5 text-2xl font-semibold text-black md:text-center md:text-3xl">
              Recommended Videos
            </h2>
          </div>
        </div>
      </div>
      <div class="-mx-0 flex flex-wrap  p-5">
        <div class="w-full px-4 md:w-1/2 lg:w-1/3 mx-auto text-center">
          <div class="relative group mb-5  ">
            <div
              class="absolute transition
               rounded-lg opacity-25 -inset-1 bg-gradient-to-r from-purple-500 to-pink-500 blur duration-400 group-hover:opacity-100 group-hover:duration-200"
            ></div>
            <div class="aspect-w-16 aspect-h-9 ">
              <iframe
                src="https://www.youtube.com/embed/56YzjsZr4hQ?si=HION5TaKkBYSNIYH"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>

          <h4 class="text-dark mb-10 text-xl font-semibold">
            Dr Jack Newman's Visual Guide to Breastfeeding
          </h4>
        </div>

        <div class="w-full px-4 md:w-1/2 lg:w-1/3 mx-auto text-center">
          <div class="relative group mb-5  ">
            <div
              class="absolute transition
               rounded-lg opacity-25 -inset-1 bg-gradient-to-r from-purple-500 to-pink-500 blur duration-400 group-hover:opacity-100 group-hover:duration-200"
            ></div>
            <div class="aspect-w-16 aspect-h-9 ">
              <iframe
                src="https://www.youtube.com/embed/MZARPE9RUGE?si=awbouROd97p3GvSl"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>

          <h4 class="text-dark mb-10 text-xl font-semibold">
            Side lying Breastfeeding How To
          </h4>
        </div>
        <div class="w-full px-4 md:w-1/2 lg:w-1/3 mx-auto text-center ">
          <div class="relative group mb-5  ">
            <div
              class="absolute transition
               rounded-lg opacity-25 -inset-1 bg-gradient-to-r from-purple-500 to-pink-500 blur duration-400 group-hover:opacity-100 group-hover:duration-200"
            ></div>
            <div class="aspect-w-16 aspect-h-9 ">
              <iframe
                src="https://www.youtube.com/embed/crdQy8zliZw?si=DdrmI-e33yZ5GcvP"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>

          <h4 class="text-dark mb-10 text-xl font-semibold">
            The Happiest Baby on the Block
          </h4>
        </div>
      </div>

      <div class="-mx-4 flex flex-wrap mt-20">
        <div class="w-full px-4">
          <div class="mx-auto  max-w-[510px] text-center lg:mb-10">
            <h2 class="mb-5 text-2xl font-semibold text-black md:text-center md:text-2xl">
              Recommended External Resources
            </h2>
          </div>
        </div>
      </div>
      <div class="-mx-0 flex flex-wrap  p-5">
        <div class="w-full px-4 md:w-1/2 lg:w-1/2 mx-auto text-center ">
          <div class="relative group mb-5  ">
            <div
              class="absolute transition
               rounded-lg opacity-25 -inset-1 bg-gradient-to-r from-purple-500 to-pink-500 blur duration-400 group-hover:opacity-100 group-hover:duration-200"
            ></div>
            <a href="https://firstdroplets.com/" target="_blank">
              <div class="aspect-w-16 aspect-h-6 ">
                <img src={Droplet}></img>
              </div>
            </a>
          </div>
          <h4 class="text-dark mb-10 text-xl font-semibold">Droplet</h4>
        </div>
        <div class="w-full px-4 md:w-1/2 lg:w-1/2 mx-auto text-center ">
          <div class="relative group mb-5  ">
            <div
              class="absolute transition
               rounded-lg opacity-25 -inset-1 bg-gradient-to-r from-purple-500 to-pink-500 blur duration-400 group-hover:opacity-100 group-hover:duration-200"
            ></div>
            <a href="https://ibconline.ca/" target="_blank">
              <div class="aspect-w-16 aspect-h-6 ">
                <img src={ibc}></img>
              </div>
            </a>
          </div>

          <h4 class="text-dark mb-10 text-xl font-semibold">
            International Breastfeeding Centre
          </h4>
        </div>
      </div>
    </section>
  );
}
