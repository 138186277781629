import React, { useState } from "react";
import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";
import Lottie from "lottie-react";
import Hero from "./Hero";

import headerLottie from "../assets/lotti/heart.json";
import { Transition } from "@headlessui/react";
export default function Header({ history }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div>
      <nav className=" ">
        <div className=" border-gray-200 px-4 lg:px-6 py-2.5 dark:bg-white-800">
          <div className="flex items-center justify-between mx-auto h-30">
            <div className="flex flex-wrap justify-between items-center lg:mx-auto md:m-0 max-w-screen-xl">
              <a href="/" class="flex flex-start ">
                <Lottie
                  animationData={headerLottie}
                  className=" w-[130px]  lg:w-[130px]"
                />
              </a>

              <div
                className="hidden  justify-between items-center w-full lg:flex lg:w-auto lg:order-1"
                id="mobile-menu-2"
              >
                <ul class="flex ml-10 flex-col mt-4 font-medium lg:flex-row lg:space-x-10 lg:mt-0">
                  <li>
                    <a
                      href="/"
                      class="block py-2 pr-4 pl-3 text-black  border-b border-gray-100 lg:hover:bg-transparent lg:border-0  lg:p-0 dark:text-black-400  dark:border-gray-700"
                      aria-current="page"
                    >
                      Home
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://lactationnetwork.com/request-a-consultant/?group=0018V00002YjW90QAF&group_name=Breastfeeding%20Telehealth%20of%20Oklahoma,%20LLC&disable_visit_type"
                      target="_blank"
                      class="block py-2 pr-4 pl-3 text-black  border-b border-gray-100 lg:hover:bg-transparent lg:border-0  lg:p-0 dark:text-black-400  dark:border-gray-700"
                      aria-current="page"
                    >
                      Get Started
                    </a>
                  </li>
                  <li>
                    <a
                      href="/services"
                      class="block py-2 pr-4 pl-3 text-black  border-b border-gray-100 lg:hover:bg-transparent lg:border-0  lg:p-0 dark:text-black-400  dark:border-gray-700"
                    >
                      Services
                    </a>
                  </li>
                  <li>
                    <a
                      href="/resources"
                      class="block py-2 pr-4 pl-3 text-black  border-b border-gray-100 lg:hover:bg-transparent lg:border-0  lg:p-0 dark:text-black-400  dark:border-gray-700"
                    >
                      Resources
                    </a>
                  </li>
                  <li>
                    <a
                      href="/products"
                      class="block py-2 pr-4 pl-3 text-black  border-b border-gray-100 lg:hover:bg-transparent lg:border-0  lg:p-0 dark:text-black-400  dark:border-gray-700"
                    >
                      Products
                    </a>
                  </li>
                  <li>
                    <a
                      href="/about"
                      class="block py-2 pr-4 pl-3 text-black  border-b border-gray-100 lg:hover:bg-transparent lg:border-0  lg:p-0 dark:text-black-400  dark:border-gray-700"
                    >
                      About
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="-mr-2 p-5 flex md:hidden">
              <button
                onClick={() => setIsOpen(!isOpen)}
                type="button"
                className=" inline-flex items-center justify-center p-2 rounded-md text-black hover:text-black hover: focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                aria-controls="mobile-menu"
                aria-expanded="false"
              >
                <span className="sr-only">Open main menu</span>
                {!isOpen ? (
                  <svg
                    className="block h-8 w-8"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                ) : (
                  <svg
                    className="block h-8 w-8"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>

        <Transition
          show={isOpen}
          enter="transition ease-out duration-100 transform"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-75 transform"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          {(ref) => (
            <div className="md:hidden" id="mobile-menu">
              <div
                ref={ref}
                className="px-2 pt-2 pb-3 space-y-1 sm:px-3 text-center"
              >
                <a
                  href="/"
                  className=" text-black block px-3 py-2 rounded-md text-base font-medium"
                >
                  Home
                </a>
                <a
                  href="https://lactationnetwork.com/request-a-consultant/?group=0018V00002YjW90QAF&group_name=Breastfeeding%20Telehealth%20of%20Oklahoma,%20LLC&disable_visit_type"
                  target="_blank"
                  className=" text-black block px-3 py-2 rounded-md text-base font-medium"
                >
                  Get Started
                </a>

                <a
                  href="/resources"
                  className="text-black-300  block px-3 py-2 rounded-md text-base font-medium"
                >
                  Resources
                </a>
                <a
                  href="/products"
                  className="text-black-300  block px-3 py-2 rounded-md text-base font-medium"
                >
                  Products
                </a>

                <a
                  href="/about"
                  className="text-black-300  block px-3 py-2 rounded-md text-base font-medium"
                >
                  About Us
                </a>
              </div>
            </div>
          )}
        </Transition>
      </nav>

      <header className="bg-white shadow"></header>
    </div>
  );
}
