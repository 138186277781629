import Koala from "../assets/Koala.jpg";
import Simple from "../assets/Simple.jpg";
import Kindred from "../assets/Kindred.png";
import Wishes from "../assets/Wishes.jpg";
import Haakaa from "../assets/Haakaa.jpg";
import Haakaa2 from "../assets/Haakaa2.jpg";
import Pillow from "../assets/pillow.jpg";
import Quick from "../assets/quick.png";
import Pacifier from "../assets/pacifier.jpg";
import Mam from "../assets/Mam.jpg";
import Lansinoh from "../assets/Lansionoh.jpg";
import Spectra from "../assets/Spectra.jpg";
import Medela from "../assets/Medela.jpg";
import Elvie from "../assets/Elvie.jpg";
import LearnMoreComponent from "./LearnMoreContact";
export default function ProductComponent() {
  return (
    <section id="Projects" class="mt-10 mb-20 padding-10 ">
      <div class="container mx-auto text-center ">
        <div class="-mx-0 flex flex-wrap  p-5">
          <div class="w-full px-4 md:w-1/2 lg:w-1/3 ">
            <a
              href="https://www.amazon.com/Simple-Wishes-Supermom-Supports-Removable/dp/B09TGH8BHP/ref=sr_1_1_sspa?crid=TICLP0ELUCL7&amp;keywords=simple%252Bwishes%252Bsupermom%252Bpumping&amp;qid=1695493406&amp;sprefix=simple%252Bwishes%252Bsupermom%252Bpumping%252Caps%252C120&amp;sr=8-1-spons&amp;sp_csd=d2lkZ2V0TmFtZT1zcF9hdGY&amp;th=1&amp;psc=1&_encoding=UTF8&tag=bth0f-20&linkCode=ur2&linkId=b6b73356d13188bc833b3b149ea7df6f&camp=1789&creative=9325"
              target="_blank"
              class="cursor-pointer"
            >
              <div class="relative group mb-8   ">
                <div
                  class="absolute transition
                 rounded-lg opacity-25 -inset-1 bg-gradient-to-r from-purple-500 to-pink-500 blur duration-400 group-hover:opacity-100 group-hover:duration-200 "
                ></div>
                <div class="relative p-10 bg-white ring-1 ring-gray-900/5 rounded-[20px]    ">
                  <div class="  flex h-[180px] w-[100px]flex items-center justify-center  mx-auto rounded-2xl">
                    <img
                      src={Wishes}
                      alt="Product"
                      className="h-[150px] w-[100px]"
                    />
                  </div>
                  <h4 class="text-gray-400 mr-3 uppercase text-xs mb-2">
                    Simple Wishes
                  </h4>
                  <p class="text-lg font-bold text-black  block capitalize break-normal">
                    Pumping and Nursing Bra in One - Adjustable Pumping Bra
                    Hands Free
                  </p>

                  <p class="text-lg font-semibold text-purple-500 text-black cursor-auto my-3">
                    $29.99
                  </p>
                </div>
              </div>
            </a>
          </div>
          <div class="w-full px-4 md:w-1/2 lg:w-1/3 ">
            <a
              href="https://ninnico.com/collections/frontpage"
              target="_blank"
              class="cursor-pointer"
            >
              <div class="relative group mb-8   ">
                <div
                  class="absolute transition
                 rounded-lg opacity-25 -inset-1 bg-gradient-to-r from-purple-500 to-pink-500 blur duration-400 group-hover:opacity-100 group-hover:duration-200 "
                ></div>
                <div class="relative p-10 bg-white ring-1 ring-gray-900/5 rounded-[20px]    ">
                  <div class="  flex h-[210px] w-[100px]flex items-center justify-center  mx-auto rounded-2xl">
                    <img
                      src={Pacifier}
                      alt="Product"
                      className="h-[150px] w-[150px]"
                    />
                  </div>
                  <h4 class="text-gray-400 mr-3 uppercase text-xs mb-2">
                    Ninni
                  </h4>
                  <p class="text-lg font-bold text-black  block capitalize break-normal">
                    3 Ninni Pacifiers
                    <br />
                  </p>

                  <p class="text-lg font-semibold text-purple-500 text-black cursor-auto my-3 ">
                    $34.99
                  </p>
                </div>
              </div>
            </a>
          </div>
          <div class="w-full px-4 md:w-1/2 lg:w-1/3 ">
            <a
              href="https://www.amazon.com/Simple-Wishes-Signature-Pumping-Patented/dp/B004S8MGGQ/ref=sr_1_1_sspa?crid=2XA3IZX0WVVF2&amp;keywords=simple+wishes+hands+free&amp;qid=1695493608&amp;s=baby-products&amp;sprefix=simple+wishes+hands+free%252Cbaby-products%252C120&amp;sr=1-1-spons&amp;sp_csd=d2lkZ2V0TmFtZT1zcF9hdGY&amp;psc=1&_encoding=UTF8&tag=bth0f-20&linkCode=ur2&linkId=35be5b8ba81ce5080c1ec7a30cd793f7&camp=1789&creative=9325"
              target="_blank"
              class="cursor-pointer"
            >
              <div class="relative group mb-8   ">
                <div
                  class="absolute transition
                 rounded-lg opacity-25 -inset-1 bg-gradient-to-r from-purple-500 to-pink-500 blur duration-400 group-hover:opacity-100 group-hover:duration-200 "
                ></div>
                <div class="relative p-10 bg-white ring-1 ring-gray-900/5 rounded-[20px]    ">
                  <div class="  flex h-[210px] w-[100px]flex items-center justify-center  mx-auto rounded-2xl">
                    <img
                      src={Simple}
                      alt="Product"
                      className="h-[150px] w-[150px]"
                    />
                  </div>
                  <h4 class="text-gray-400 mr-3 uppercase text-xs mb-2">
                    Simple Wishes
                  </h4>
                  <p class="text-lg font-bold text-black  block capitalize break-normal">
                    Hands-Free Pumping Bra
                  </p>

                  <p class="text-lg font-semibold text-purple-500 text-black cursor-auto my-3">
                    $29.99
                  </p>
                </div>
              </div>
            </a>
          </div>
          <div class="w-full px-4 md:w-1/2 lg:w-1/3 ">
            <a
              href="https://www.amazon.com/Adjustable-Nursing-Customizable-Breastfeeding-Comfort/dp/B08QPLVP2X/ref=sr_1_2?crid=28U3QTWVTWQX0&amp&keywords=frida+nursing+pillow&amp&qid=1695494160&amp&sprefix=frida+nursing+pillow%252Caps%252C99&amp&sr=8-2&_encoding=UTF8&tag=bth0f-20&linkCode=ur2&linkId=f118eebe1255c979077092fb0a10de9d&camp=1789&creative=9325"
              target="_blank"
              class="cursor-pointer"
            >
              <div class="relative group mb-8   ">
                <div
                  class="absolute transition
                 rounded-lg opacity-25 -inset-1 bg-gradient-to-r from-purple-500 to-pink-500 blur duration-400 group-hover:opacity-100 group-hover:duration-200 "
                ></div>
                <div class="relative p-10 bg-white ring-1 ring-gray-900/5 rounded-[20px]    ">
                  <div class="  flex h-[210px] w-[100px]flex items-center justify-center  mx-auto rounded-2xl">
                    <img
                      src={Pillow}
                      alt="Product"
                      className="h-[150px] w-[150px]"
                    />
                  </div>
                  <h4 class="text-gray-400 mr-3 uppercase text-xs mb-2">
                    Frida
                  </h4>
                  <p class="text-lg font-bold text-black  block capitalize break-normal">
                    Adjustable Nursing Pillow
                  </p>

                  <p class="text-lg font-semibold text-purple-500 text-black cursor-auto my-3">
                    $49.99
                  </p>
                </div>
              </div>
            </a>
          </div>
          <div class="w-full px-4 md:w-1/2 lg:w-1/3 ">
            <a
              href="https://www.amazon.com/Nipple-Shields-in-Trilaminate-Silver/dp/B07ND58735/ref=sr_1_1_sspa?crid=341HLOK83SRTC&amp;keywords=koala+babycare+the+original+silver+nursing+cups&amp;qid=1695479798&amp;sprefix=%252Caps%252C168&amp;sr=8-1-spons&amp;sp_csd=d2lkZ2V0TmFtZT1zcF9hdGY&amp;psc=1&_encoding=UTF8&tag=bth0f-20&linkCode=ur2&linkId=f6225efa2663b2844b0ebdca097f4b53&camp=1789&creative=9325"
              target="_blank"
              class="cursor-pointer"
            >
              <div class="relative group mb-8   ">
                <div
                  class="absolute transition
                 rounded-lg opacity-25 -inset-1 bg-gradient-to-r from-purple-500 to-pink-500 blur duration-400 group-hover:opacity-100 group-hover:duration-200 "
                ></div>
                <div class="relative p-10 bg-white ring-1 ring-gray-900/5 rounded-[20px]    ">
                  <div class="  flex h-[210px] w-[100px]flex items-center justify-center  mx-auto rounded-2xl">
                    <img
                      src={Koala}
                      alt="Product"
                      className="h-[150px] w-[150px]"
                    />
                  </div>
                  <h4 class="text-gray-400 mr-3 uppercase text-xs mb-2">
                    Koala Babycare
                  </h4>
                  <p class="text-lg font-bold text-black  block capitalize break-normal">
                    Nipple Shields for Nursing Newborn
                  </p>

                  <p class="text-lg font-semibold text-purple-500 text-black cursor-auto my-3">
                    $39.75
                  </p>
                </div>
              </div>
            </a>
          </div>
          <div class="w-full px-4 md:w-1/2 lg:w-1/3 ">
            <a
              href="https://www.amazon.com/Organic-Washable-Reusable-Nursing-Breastfeeding/dp/B09DQ6J9P5/ref=sr_1_2?keywords=kindred+bravely+organic+reusable+nursing&amp&qid=1695493763&amp&sr=8-2&_encoding=UTF8&tag=bth0f-20&linkCode=ur2&linkId=fef08d1bf2898778ac257edceff30600&camp=1789&creative=9325"
              target="_blank"
              class="cursor-pointer"
            >
              <div class="relative group mb-8   ">
                <div
                  class="absolute transition
                 rounded-lg opacity-25 -inset-1 bg-gradient-to-r from-purple-500 to-pink-500 blur duration-400 group-hover:opacity-100 group-hover:duration-200 "
                ></div>
                <div class="relative p-10 bg-white ring-1 ring-gray-900/5 rounded-[20px]    ">
                  <div class="  flex h-[180px] w-[100px]flex items-center justify-center  mx-auto rounded-2xl">
                    <img
                      src={Kindred}
                      alt="Product"
                      className="h-[150px] w-[150px]"
                    />
                  </div>
                  <h4 class="text-gray-400 mr-3 uppercase text-xs mb-2">
                    Kindred Bravely
                  </h4>
                  <p class="text-lg font-bold text-black  block capitalize break-normal">
                    Washable Breast Pads for Breastfeeding with Carry Bag
                  </p>

                  <p class="text-lg font-semibold text-purple-500 text-black cursor-auto my-3">
                    $14.99
                  </p>
                </div>
              </div>
            </a>
          </div>
          <div class="w-full px-4 md:w-1/2 lg:w-1/3 ">
            <a
              href="https://www.amazon.com/Medela-Quick-Micro-Steam-Economy-retail/dp/B0139J3RGW/ref=sr_1_2?crid=XH83GA23XSRT&amp;keywords=medela%252Bquick%252Bclean%252Bmicro-steam%252Bbags&amp;qid=1695479921&amp;sprefix=%252Caps%252C147&amp;sr=8-2&amp;th=1&_encoding=UTF8&tag=bth0f-20&linkCode=ur2&linkId=1eed6f4a175bef19ccabab0bb1bf1191&camp=1789&creative=9325"
              target="_blank"
              class="cursor-pointer"
            >
              <div class="relative group mb-8   ">
                <div
                  class="absolute transition
                 rounded-lg opacity-25 -inset-1 bg-gradient-to-r from-purple-500 to-pink-500 blur duration-400 group-hover:opacity-100 group-hover:duration-200 "
                ></div>
                <div class="relative p-10 bg-white ring-1 ring-gray-900/5 rounded-[20px]    ">
                  <div class="  flex h-[210px] w-[100px]flex items-center justify-center  mx-auto rounded-2xl">
                    <img
                      src={Quick}
                      alt="Product"
                      className="h-[150px] w-[150px]"
                    />
                  </div>
                  <h4 class="text-gray-400 mr-3 uppercase text-xs mb-2">
                    Medela
                  </h4>
                  <p class="text-lg font-bold text-black  block capitalize break-normal">
                    Quick Clean Micro-Steam Bags
                  </p>

                  <p class="text-lg font-semibold text-purple-500 text-black cursor-auto my-3">
                    $28.99
                  </p>
                </div>
              </div>
            </a>
          </div>
          <div class="w-full px-4 md:w-1/2 lg:w-1/3 ">
            <a
              href="https://www.amazon.com/Colostrum-Collector-Portable-Breastfeeding-Collect/dp/B08PTQSKPT/ref=sr_1_2_sspa?crid=12B3TP42M3GG&amp&keywords=hakka+colostrum+collection&amp&qid=1695494519&amp&sprefix=hakka%252Caps%252C135&amp&sr=8-2-spons&amp&sp_csd=d2lkZ2V0TmFtZT1zcF9hdGY&amp&psc=1&_encoding=UTF8&tag=bth0f-20&linkCode=ur2&linkId=4b5ab8c3d947af2b3ab2495b9b3f5698&camp=1789&creative=9325"
              target="_blank"
              class="cursor-pointer"
            >
              <div class="relative group mb-8   ">
                <div
                  class="absolute transition
                 rounded-lg opacity-25 -inset-1 bg-gradient-to-r from-purple-500 to-pink-500 blur duration-400 group-hover:opacity-100 group-hover:duration-200 "
                ></div>
                <div class="relative p-10 bg-white ring-1 ring-gray-900/5 rounded-[20px]    ">
                  <div class="  flex h-[180px] w-[100px]flex items-center justify-center  mx-auto rounded-2xl">
                    <img
                      src={Haakaa}
                      alt="Product"
                      className="h-[150px] w-[150px]"
                    />
                  </div>
                  <h4 class="text-gray-400 mr-3 uppercase text-xs mb-2">
                    Haakaa
                  </h4>
                  <p class="text-lg font-bold text-black  block capitalize break-normal">
                    Breast Milk Collector with Cotton Cloth Wipe and Storage
                    Case
                  </p>

                  <p class="text-lg font-semibold text-purple-500 text-black cursor-auto my-3">
                    $19.49
                  </p>
                </div>
              </div>
            </a>
          </div>
          <div class="w-full px-4 md:w-1/2 lg:w-1/3 ">
            <a
              href="https://www.amazon.com/MAM-Start-Anti-Colic-Bottles-4-Count/dp/B08V4DQ1GL/ref=sr_1_6?crid=AZD1L78O6PHK&amp;keywords=mam+slow+flow+bottles&amp;qid=1695493032&amp;sprefix=mam+slow+flow%252Caps%252C126&amp;sr=8-6&_encoding=UTF8&tag=bth0f-20&linkCode=ur2&linkId=d084321aa9d83725bdcdc4b185ce0619&camp=1789&creative=9325"
              target="_blank"
              class="cursor-pointer"
            >
              <div class="relative group mb-8   ">
                <div
                  class="absolute transition
                 rounded-lg opacity-25 -inset-1 bg-gradient-to-r from-purple-500 to-pink-500 blur duration-400 group-hover:opacity-100 group-hover:duration-200 "
                ></div>
                <div class="relative p-10 bg-white ring-1 ring-gray-900/5 rounded-[20px]    ">
                  <div class="  flex h-[210px] w-[100px]flex items-center justify-center  mx-auto rounded-2xl">
                    <img
                      src={Mam}
                      alt="Product"
                      className="h-[150px] w-[150px]"
                    />
                  </div>
                  <h4 class="text-gray-400 mr-3 uppercase text-xs mb-2">MAM</h4>
                  <p class="text-lg font-bold text-black  block capitalize break-normal">
                    Anti-Colic Slow Flow Bottles 5 oz
                  </p>

                  <p class="text-lg font-semibold text-purple-500 text-black cursor-auto my-3">
                    $25.83
                  </p>
                </div>
              </div>
            </a>
          </div>
          <div class="w-full px-4 md:w-1/2 lg:w-1/3 ">
            <a
              href="https://www.amazon.com/Lansinoh-Nursing-Packs-Disposable-Breast/dp/B06XPHR7DF/ref=sr_1_2?crid=RL4FO30ZZGO4&amp&keywords=disposable%252Bbreast%252Bpads%252Babsorbent&amp&qid=1695493939&amp&sprefix=disposable%252Bbreast%252Bpads%252Babsorbant%252Caps%252C133&amp&sr=8-2&amp&th=1&_encoding=UTF8&tag=bth0f-20&linkCode=ur2&linkId=99df0c14158a374ab8639a572cb31966&camp=1789&creative=9325"
              target="_blank"
              class="cursor-pointer"
            >
              <div class="relative group mb-8   ">
                <div
                  class="absolute transition
                 rounded-lg opacity-25 -inset-1 bg-gradient-to-r from-purple-500 to-pink-500 blur duration-400 group-hover:opacity-100 group-hover:duration-200 "
                ></div>
                <div class="relative p-10 bg-white ring-1 ring-gray-900/5 rounded-[20px]    ">
                  <div class="  flex h-[210px] w-[100px]flex items-center justify-center  mx-auto rounded-2xl">
                    <img
                      src={Lansinoh}
                      alt="Product"
                      className="h-[150px] w-[150px]"
                    />
                  </div>
                  <h4 class="text-gray-400 mr-3 uppercase text-xs mb-2">MAM</h4>
                  <p class="text-lg font-bold text-black  block capitalize break-normal">
                    Disposable Nursing Pads, Soft and Super Absorbent Breast
                    Pads
                  </p>

                  <p class="text-lg font-semibold text-purple-500 text-black cursor-auto my-3">
                    $19.89
                  </p>
                </div>
              </div>
            </a>
          </div>
          <div class="w-full px-4 md:w-1/2 lg:w-1/3 ">
            <a
              href="https://www.amazon.com/Spectra-Portable-Adjustable-Breastfeeding-Essential/dp/B0BTK2W1QD/ref=sr_1_2?crid=3W43NDBPKP30E&amp&keywords=spectra+gold+breast+pump&amp&qid=1695494277&amp&sprefix=spectra+gold+%252Caps%252C130&amp&sr=8-2&amp&ufe=app_do%253Aamzn1.fos.c3015c4a-46bb-44b9-81a4-dc28e6d374b3&_encoding=UTF8&tag=bth0f-20&linkCode=ur2&linkId=0adfbd8d0d985367f20409c7c80cf1c7&camp=1789&creative=9325"
              target="_blank"
              class="cursor-pointer"
            >
              <div class="relative group mb-8   ">
                <div
                  class="absolute transition
                 rounded-lg opacity-25 -inset-1 bg-gradient-to-r from-purple-500 to-pink-500 blur duration-400 group-hover:opacity-100 group-hover:duration-200 "
                ></div>
                <div class="relative p-10 bg-white ring-1 ring-gray-900/5 rounded-[20px]    ">
                  <div class="  flex h-[240px] w-[100px]flex items-center justify-center  mx-auto rounded-2xl">
                    <img
                      src={Spectra}
                      alt="Product"
                      className="h-[150px] w-[150px]"
                    />
                  </div>
                  <h4 class="text-gray-400 mr-3 uppercase text-xs mb-2">
                    Spectra
                  </h4>
                  <p class="text-lg font-bold text-black  block capitalize break-normal">
                    Dual Adjustable Electric Breast Pump
                  </p>

                  <p class="text-lg font-semibold text-purple-500 text-black cursor-auto my-3">
                    $350.00
                  </p>
                </div>
              </div>
            </a>
          </div>
          <div class="w-full px-4 md:w-1/2 lg:w-1/3 ">
            <a
              href="https://www.amazon.com/Elvie-Breast-Pump-Automated-Personalized/dp/B0C4FT6Q1F/ref=sr_1_2_sspa?crid=32RSO4KM36N2&amp&keywords=willow+breast+pump&amp&qid=1695494397&amp&sprefix=willow+%252Caps%252C150&amp&sr=8-2-spons&amp&ufe=app_do%253Aamzn1.fos.c3015c4a-46bb-44b9-81a4-dc28e6d374b3&amp&sp_csd=d2lkZ2V0TmFtZT1zcF9hdGY&amp&psc=1&_encoding=UTF8&tag=bth0f-20&linkCode=ur2&linkId=efda49464499f941dcdc117ed08ff6f3&camp=1789&creative=9325"
              target="_blank"
              class="cursor-pointer"
            >
              <div class="relative group mb-8   ">
                <div
                  class="absolute transition
                 rounded-lg opacity-25 -inset-1 bg-gradient-to-r from-purple-500 to-pink-500 blur duration-400 group-hover:opacity-100 group-hover:duration-200 "
                ></div>
                <div class="relative p-10 bg-white ring-1 ring-gray-900/5 rounded-[20px]    ">
                  <div class="  flex h-[210px] w-[100px]flex items-center justify-center  mx-auto rounded-2xl">
                    <img
                      src={Elvie}
                      alt="Product"
                      className="h-[150px] w-[150px]"
                    />
                  </div>
                  <h4 class="text-gray-400 mr-3 uppercase text-xs mb-2">
                    Elvie
                  </h4>
                  <p class="text-lg font-bold text-black  block capitalize break-normal">
                    Breast Pump - Double, Wearable Breast Pump with App
                  </p>

                  <p class="text-lg font-semibold text-purple-500 text-black cursor-auto my-3">
                    $414.00
                  </p>
                </div>
              </div>
            </a>
          </div>
          <div class="w-full px-4 md:w-1/2 lg:w-1/3 ">
            <a
              href="https://www.amazon.com/Haakaa-Breast-Manual-Silicone-Breastfeeding/dp/B07CWK4S5W/ref=sr_1_3_sspa?crid=12B3TP42M3GG&amp&keywords=hakka+colostrum+collection&amp&qid=1695494634&amp&sprefix=hakka%252Caps%252C135&amp&sr=8-3-spons&amp&sp_csd=d2lkZ2V0TmFtZT1zcF9hdGY&amp&psc=1&_encoding=UTF8&tag=bth0f-20&linkCode=ur2&linkId=e2e0345ce80fc49231623f6838098dd0&camp=1789&creative=9325"
              target="_blank"
              class="cursor-pointer"
            >
              <div class="relative group mb-8   ">
                <div
                  class="absolute transition
                 rounded-lg opacity-25 -inset-1 bg-gradient-to-r from-purple-500 to-pink-500 blur duration-400 group-hover:opacity-100 group-hover:duration-200 "
                ></div>
                <div class="relative p-10 bg-white ring-1 ring-gray-900/5 rounded-[20px]    ">
                  <div class="  flex h-[210px] w-[100px]flex items-center justify-center  mx-auto rounded-2xl">
                    <img
                      src={Haakaa2}
                      alt="Product"
                      className="h-[150px] w-[150px]"
                    />
                  </div>
                  <h4 class="text-gray-400 mr-3 uppercase text-xs mb-2">
                    Haakaa
                  </h4>
                  <p class="text-lg font-bold text-black  block capitalize break-normal">
                    Manual Breast Pump for Breastfeeding 4oz/100ml
                  </p>

                  <p class="text-lg font-semibold text-purple-500 text-black cursor-auto my-3">
                    $12.94
                  </p>
                </div>
              </div>
            </a>
          </div>
          <div class="w-full px-4 md:w-1/2 lg:w-1/3 ">
            <a
              href="https://www.amazon.com/Medela-Manual-Breast-Pump-Set/dp/B0BFJK96CW/ref=sr_1_2?crid=199708R2MHZKT&amp&keywords=medela+harmony+manual+breast+pump&amp&qid=1695494733&amp&sprefix=medela+harmon%252Caps%252C127&amp&sr=8-2&_encoding=UTF8&tag=bth0f-20&linkCode=ur2&linkId=ec82ff1630862187f77a64cf0cc52ae6&camp=1789&creative=9325"
              target="_blank"
              class="cursor-pointer"
            >
              <div class="relative group mb-8   ">
                <div
                  class="absolute transition
                 rounded-lg opacity-25 -inset-1 bg-gradient-to-r from-purple-500 to-pink-500 blur duration-400 group-hover:opacity-100 group-hover:duration-200 "
                ></div>
                <div class="relative p-10 bg-white ring-1 ring-gray-900/5 rounded-[20px]    ">
                  <div class="  flex h-[180px] w-[100px]flex items-center justify-center  mx-auto rounded-2xl">
                    <img
                      src={Medela}
                      alt="Product"
                      className="h-[150px] w-[150px]"
                    />
                  </div>
                  <h4 class="text-gray-400 mr-3 uppercase text-xs mb-2">
                    Medela
                  </h4>
                  <p class="text-lg font-bold text-black  block capitalize break-normal">
                    Manual Breast Pump Set - Perfect Pair Bundle, Includes
                    Harmony Manual Breast Pump & Silicone Breastmilk Collector
                  </p>

                  <p class="text-lg font-semibold text-purple-500 text-black cursor-auto my-3">
                    $39.99
                  </p>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
