import Services from "../Components/Services";
import BreakLotti from "../Components/BreakLotti";
import Footer from "../Components/Footer";

import Hero from "../Components/Hero";
import Empty from "../Components/Empty";

export default function Home() {
  return (
    <div className="overflow-hidden  ">
      <BreakLotti />
      <Services />
      <Empty />
    </div>
  );
}
